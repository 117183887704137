/* StylishTextPage.css */

/* Default styles */
.stylish-container {
  background-color: #292929;
  padding: 10px;
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
  color: #ffffff;
  margin-top: 10px;
  border-radius: 10px; /* 角を10px丸める */
}

.first-stylish {
  color: #ffffff;
  padding: 20px;
  background-color: transparent; /* 背景を透明にする */
}

.stylish-content {
  text-align: left;
  padding: 20px;
  margin-top: 20px;
}

.stylish-content ol {
  list-style-type: decimal;
}

.stylish-content li {
  margin-bottom: 10px;
}

h2 {
  color: #000000; /* 文字の色を黒に設定 */
}

h3 {
  color: black;
}

.paragraph {
  font-size: 16px; /* フォントサイズを16ピクセルに設定 */
  line-height: 1.5; /* 行の高さをフォントサイズの1.5倍に設定 */
  margin-bottom: 1em; /* 下の余白を1emに設定して段落を分ける */
  color: #ffffff; /* テキストの色を設定 */
}

/* ニュース用セクション */
.news-section {
  background-color: #292929;
  margin: 20px 0;
  width: 50%; /* 横幅を80%に設定 */
  margin-left: auto;
  margin-right: auto; /* 中央に配置 */
  border-radius: 10px; /* 角を10px丸める */
  margin-top: 0px;
}

.news-list {
  list-style-type: none;
  padding: 0;
  font-size: 16px;
}

.news-list li {
  margin: 5px 0;
}


.twitter-follow-button {
  font-size: 1000px; /* ボタンのテキストサイズ */
  padding: 10px 10px; /* ボタンのパディング */
  border-radius: 5px; /* ボタンの角を丸める */
  color: #ffffff; /* ボタンの文字色 */
  display: inline-block; /* ボタンをインラインブロックに設定 */
  text-decoration: none; /* ボタン内のリンクの下線を削除 */
}

.twitter-follow-button:hover {
  background-color: #ced7dd; /* ホバー時の背景色変更 */
}






/* Styles for iPad and smaller devices */
@media (max-width: 768px) {
  .stylish-container {
    padding: 15px; /* スマホ用のパディング調整 */
    max-width: 65%; /* コンテナの幅を画面いっぱいに広げる */
  }

  .first-stylish {
    padding: 15px; /* スマホ用のパディング調整 */
  }

  .stylish-content {
    padding: 15px; /* スマホ用のパディング調整 */
    margin-top: 10px; /* スマホ用のマージン調整 */
  }

  .stylish-content ol {
    font-size: 14px; /* スマホ用フォントサイズ調整 */
  }

  .stylish-content li {
    margin-bottom: 8px; /* スマホ用マージン調整 */
  }

  h3 {
    font-size: 18px; /* スマホ用のフォントサイズ調整 */
  }

  .paragraph {
    font-size: 14px; /* スマホ用のフォントサイズ調整 */
    line-height: 1.4; /* スマホ用の行の高さ調整 */
    margin-bottom: 0.8em; /* スマホ用の下の余白調整 */
  }

  .news-list {
    font-size: 14px; /* スマホ用のフォントサイズ調整 */
  }
}

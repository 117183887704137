/* CreatorPage.css */

/* 基本スタイル */
.creator-page {
  padding: 20px;
}

.creator-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* コンテナのアイテムを水平方向に中央に配置 */
}

.creator {
  display: flex;
  flex-direction: row; /* デフォルトは2列表示 */
  background-color: #292929; /* クリエイター情報の背景色（ダークモード風） */
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: 10px;
  width: calc(50% - 20px); /* 2列にするために幅を調整 */
  margin-right: 20px; /* クリエイターの間隔を設定 */
}

.creator-info {
  flex: 1;
  padding: 20px;
}

.creator-image {
  flex: 1;
  padding: 20px;
}

.creator img {
  width: 100%; /* 画像をコンテナに合わせてリサイズ */
}

/* スマホやiPad以下のサイズのスタイル */
@media (max-width: 768px) {
  .creator-container {
    flex-direction: column; /* 1列表示に切り替え */
  }

  .creator {
    flex-direction: column; /* 1列表示にするための変更 */
    width: 100%; /* 幅を100%にして1列表示にする */
    margin-right: 0; /* 右側のマージンを削除 */
    margin-bottom: 20px; /* 下にマージンを追加 */
  }

  .creator-image {
    order: 1; /* 画像を先に表示 */
  }

  .creator-info {
    order: 2; /* 情報をその後に表示 */
  }
}
  .video-container {
    position: relative;
    width: 620px;
    height: 415px;
    
    overflow: hidden;
    margin: 0 auto; /* センターに配置 */
  }
  
  .video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    height: calc(100% - 20px);
    transition: opacity 0.5s ease-in-out; /* 透明度のトランジション */
  }
  
  .video-container .video1 {
    opacity: 1; /* 初期状態で表示 */
  }
  
  .video-container .video2 {
    opacity: 0; /* 初期状態で非表示 */
  }
  
  .video-container.sliding .video1 {
    opacity: 0; /* フェードアウトする動画 */
  }
  
  .video-container.sliding .video2 {
    opacity: 1; /* フェードインする動画 */
  }
  
/* Styles for iPad and smaller devices */
@media (max-width: 768px) {
  .video-container {
    width: 100%; /* 幅を画面いっぱいに広げる */
    height: auto; /* 高さを自動調整 */
    padding-top: 56.25%; /* 16:9 アスペクト比を保つためのパディング */
    position: relative; /* 相対位置に戻す */
  }

  .video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none; /* 枠線を削除 */
  }
}
.custom-audio-player {
    background-color: #797878; /* 背景色 */
    border-radius: 10px; /* 角を丸める */
    padding: 10px; /* コンテンツとの余白 */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* 影を追加 */
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 5px;
    margin-bottom: 10px; 
  }
  
  .progress-bar {
    margin: 0 1px;
    flex-grow: 1;
  }
  
  .volume-control {
    position: relative;
  }
  
  .volume-slider {
    position: absolute;
    right: 0;
    left: -20px;
    top: -10px; /* アイコンの上に表示 */
    transform: rotate(-90deg); /* スライダーを縦にする */
    transform-origin: top right;
    width: 55px;
    display: block;
  }

  .volume-button {
    background-color: #555;
    color: white;
    font-size: 13px; /* アイコンのサイズを統一 */
    border: none;
    padding: 8px 10px;
    border-radius: 5px;
    cursor: pointer;
    margin-left: 5px;
    margin-right: 10px;
    margin-bottom: 8px;
  }

  .download-button {
    background-color: #555;
    color: white;
    font-size: 13px; /* アイコンのサイズを統一 */
    border: none;
    padding: 8px 10px;
    border-radius: 5px;
    cursor: pointer;
    margin-left: 20px;
  }
  
  .button {
    background-color: #555;
    color: white;
    font-size: 13px; /* アイコンのサイズを統一 */
    border: none;
    padding: 8px 30px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  

  .download-button:hover,
  .volume-button:hover {
    background-color: rgb(217, 85, 221); /* ホバー時の背景色 */
  }

  .button.active  {
    background-color: #007bff;
  }
  
  .pagination {
    display: flex;
    justify-content: center;
    list-style: none;
    padding: 0;
  }
  
  .page-item {
    margin: 0 5px;
  }
  
  .page-item.active .page-link {
    background-color: #007bff;
    color: white;
  }
  
  .page-link {
    display: block;
    padding: 8px 12px;
    color: #007bff;
    text-decoration: none;
    cursor: pointer;
  }
  
  .separator {
    border: 1px dashed white;
    margin: 20px 0;
  }
  
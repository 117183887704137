.App {
  text-align: center;
}

.background-image {
  background-image: url('images/background.png');
  background-size: cover; /* 背景画像が画面全体にフィットするようにする */
  background-attachment: fixed; /* 背景画像を固定 */
  background-position: center; /* 背景画像を中央に配置 */
  background-repeat: no-repeat; /* 背景画像が繰り返されないようにする */
}


.h2{
  color: rgb(10, 5, 5);
}


.App-header {

  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.song-container {
  margin-top: 10px;
  min-width: 900px; /* 30文字が表示できる幅に設定 */
  max-width: 100%; /* 最大幅を親コンテナに合わせる */
  margin: 0 auto; /* 中央寄せ */

}

.song {
  background-color: #292929;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;

  min-width: 900px; /* 各曲のコンテナの最小幅を設定 */
  max-width: 100%; /* 最大幅を親コンテナに合わせる */

  transition: all 5s ease; /* サイズ変更をスムーズに */
}



.mainbutton {
  display: inline-block;
  padding: 20px 65px;
  background-color: white;
  border: 1px solid #000;
  border-radius: 5px;
  margin-right: 10px;
  text-decoration: none;
  color: black;
}

.button {
  display: inline-block;
  padding: 15px 25px;
  background-color: white;
  border: 1px solid #000;
  border-radius: 5px;
  margin-right: 10px;
  text-decoration: none;
  color: black;

  transition: background-color 0.3s; /* ホバー時の背景色変更のアニメーション */
}


.button:hover {
  background-color: rgb(217, 85, 221); /* ホバー時の背景色 */
}





.header-image {
  max-width: 80%; /* ヘッダーの幅いっぱいに画像を広げる */
  height: auto; /* アスペクト比を保持して画像をリサイズ */
  padding: 60px;
}


.search-bar {
  display: flex;
  align-items: center;
  justify-content: center; /* 検索バー全体を中央揃えに */
  margin-top: 20px;
  gap: 10px;
}

.search-bar input[type="text"] {
  width: 400px; /* 幅を広げてよりモダンな感じに */
  height: 30px;
  padding: 10px; /* 内側の余白を追加して快適な入力感に */
  font-size: 16px;
  border: 1px solid #615555; /* ボーダーの色を薄いグレーに */
  border-radius: 20px; /* 角を丸くして滑らかなデザインに */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* 軽い影を追加して浮き上がるような効果を */
  outline: none;
  transition: all 0.3s ease; /* ホバーやフォーカス時にアニメーション効果を */
}

.search-bar input[type="text"]:focus {
  border-color: #007bff; /* フォーカス時にボーダーの色を変更 */
  box-shadow: 0 4px 8px rgba(0, 123, 255, 0.2); /* 影を強調 */
}

.tag {
  padding: 8px 15px; /* パディングを増やしてボタンのサイズを大きくする */
  font-size: 16px; /* ボタンのテキストのフォントサイズを調整 */
  border-radius: 5px;
  min-height: 45px; /* タグボタンの最小高さを設定 */

}




.pagination {
  display: flex;
  list-style: none;
  padding: 0;
  justify-content: center; /* Centers the pagination */
}

.page-item {
  margin: 0 5px; /* Adds spacing between the buttons */
}

.page-link {
  padding: 5px 10px;
  border: 1px solid #ccc;
  border-radius: 3px;
  background-color: #f8f8f8;
  cursor: pointer;
  text-decoration: none;
  color: #333;
  transition: background-color 0.3s, color 0.3s;
}

.page-link:hover {
  background-color: #333;
  color: #fff;
}

.page-item.active .page-link {
  background-color: #333;
  color: #fff;
  font-weight: bold;
}


/* App.css */

/* BGMとSE・ジングルボタンの共通スタイル */
.toggle-buttons {
  display: flex; /* 横並びにする */
  gap: 10px; /* ボタン間のスペースを調整 */
}

.toggle-buttons .button {
  align-items: center;
  margin-top: 20px;
  font-size: 15px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  transition: background-color 0.3s, color 0.3s;
}

/* BGMとSE・ジングルボタンの通常時のスタイル */
.toggle-buttons .button {
  background-color: #8eb4bb; /* ボタンの背景色 */
  color: rgb(0, 0, 0); /* テキストの色 */
}

/* アクティブなボタンのスタイル */
.toggle-buttons .button.active {
  background-color: #a1e5fc; /* アクティブなボタンの背景色 */
  color: rgb(0, 0, 0); /* テキストの色 */
}

/* ボタンのホバー時のスタイル */
.toggle-buttons .button:hover {
  background-color: #dfecce; /* ホバー時の背景色 */
}


.page-enter {
  opacity: 0;
  transform: translateY(100%); /* 下から表示 */
}

.page-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 300ms, transform 300ms;
}

.page-exit {
  opacity: 1;
  transform: translateY(0);
}




.footer-container {
  display: flex;
  justify-content: center;
}

.footer-links {
  display: flex;
  justify-content: center;
  gap: 10px;
  padding: 5px;
  border-radius: 10px;
  margin-top: 8px;
}


.footer-link-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  border-radius: 10px;
  background-color: #d5d5f7;
}

.copyright {
  margin-top: 5px;
  margin-bottom: 5px;
  text-align: center;
}




/* iPad以下のサイズ (768px以下) の場合のスタイル */
@media screen and (max-width: 768px) {
  .App {
    text-align: center;
  }

  .background-image {
    background-image: url('images/background.png');
    background-size: 120% 120%; /* 背景画像を少し大きめに表示 */
    background-position: center; /* 画像の位置を中央に保つ */
    background-attachment: fixed; /* 背景がスクロールに追従するように */
  }

  .App-header {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(8px + 2vmin); /* フォントサイズを小さくする */
    color: white;
    padding: 10px; /* 内側の余白を追加 */
  }

  .App-logo {
    height: 30vmin; /* ロゴのサイズを調整 */
  }

  .song-container {
    margin-top: 10px;
    min-width: 100%; /* 幅を親コンテナに合わせる */
  }

  .song {
    background-color: #292929;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 8px; /* 内側の余白を減らす */
    margin-bottom: 10px;
    min-width: 100%; /* 幅を親コンテナに合わせる */
  }

  .separator {
    border: none;
    border-top: 1px solid #ccc;
    margin: 5px 0; /* 上下の余白を減らす */
  }

  .mainbutton {
    display: inline-block;
    padding: 10px 30px; /* ボタンのサイズを小さくする */
    background-color: white;
    border: 1px solid #000;
    border-radius: 5px;
    margin-right: 5px; /* 余白を減らす */
    text-decoration: none;
    color: black;
    font-size: 14px; /* フォントサイズを小さくする */
  }

  .button {
    display: inline-block;
    padding: 8px 15px; /* ボタンのサイズを小さくする */
    background-color: white;
    border: 1px solid #000;
    border-radius: 5px;
    margin-right: 5px; /* 余白を減らす */
    text-decoration: none;
    color: black;
    font-size: 14px; /* フォントサイズを小さくする */
    transition: background-color 0.3s;
  }

  .button:hover {
    background-color: #d5d;
  }

  .header-image {
    max-width: 90%; /* ヘッダー画像の幅を調整 */
    height: auto;
    padding: 20px; /* 内側の余白を減らす */
  }

  .search-bar {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
    gap: 5px; /* ギャップを減らす */
  }

  .search-bar input[type="text"] {
    width: 250px; /* 幅を狭くする */
    height: 25px; /* 高さを縮小する */
    padding: 8px; /* 内側の余白を減らす */
    font-size: 14px; /* フォントサイズを小さくする */
    border: 1px solid #615555;
    border-radius: 15px; /* 角を丸くする */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    outline: none;
    transition: all 0.3s ease;
  }

  .search-bar input[type="text"]:focus {
    border-color: #007bff;
    box-shadow: 0 4px 8px rgba(0, 123, 255, 0.2);
  }

  .tag {
    padding: 6px 12px; /* パディングを減らす */
    font-size: 14px; /* フォントサイズを小さくする */
    border-radius: 5px;
    min-height: 35px; /* 最小高さを調整 */
  }

  .pagination {
    display: flex;
    list-style: none;
    padding: 0;
    justify-content: center;
  }

  .page-item {
    margin: 0 3px; /* ボタン間のスペースを減らす */
  }

  .page-link {
    padding: 3px 8px; /* ボタンのサイズを小さくする */
    border: 1px solid #ccc;
    border-radius: 3px;
    background-color: #f8f8f8;
    cursor: pointer;
    text-decoration: none;
    color: #333;
    font-size: 12px; /* フォントサイズを小さくする */
    transition: background-color 0.3s, color 0.3s;
  }

  .page-link:hover {
    background-color: #333;
    color: #fff;
  }

  .page-item.active .page-link {
    background-color: #333;
    color: #fff;
    font-weight: bold;
  }

  /* ナビゲーションタブ */
  .custom-navigation {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.9);
    width: 100%;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    z-index: 1000;
  }

  .custom-navigation a {
    margin-bottom: 5px;
    font-size: 14px; /* フォントサイズを小さくする */
    text-decoration: none;
    color: black;
  }
}

/* PCサイズの表示 */
@media screen and (min-width: 769px) {
  .custom-navigation {
    display: none; /* 通常のナビゲーションが表示されるので非表示にする */
  }
}
